import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Home,
  },
  {
    path: '/index',
    name: 'index',
    component: Home,
  },
  {
    path: '/watch',
    name: 'watch',
    component: () =>
      import(/* webpackChunkName: "watch" */ '../views/watch'),
  },
  {
    path: '/about',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/about.vue'),
  },
  {
    path: '/recommend',
    name: 'recommend',
    component: () =>
      import(/* webpackChunkName: "recommend" */ '../views/recommend.vue'),
  },
  // {
  //   path: '/shop',
  //   name: 'shop',
  //   component: () =>
  //     import(/* webpackChunkName: "shop" */ '../views/shop/index.vue'),
  // },
  {
    path: '/find',
    name: 'find',
    component: () => import(/* webpackChunkName: "find" */ '../views/find.vue'),
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import(/* webpackChunkName: "vip" */ '../views/vip.vue'),
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "live" */ '../views/live.vue'),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/liveDetail.vue'),
  },
  {
    path: '/doc1',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc1" */ '../views/articles/doc1.vue'),
  },
  {
    path: '/doc2',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc2" */ '../views/articles/doc2.vue'),
  },
  {
    path: '/doc3',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc3" */ '../views/articles/doc3.vue'),
  },
  {
    path: '/doc4',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc4" */ '../views/articles/doc4.vue'),
  },
  {
    path: '/doc5',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc5" */ '../views/articles/doc5.vue'),
  },
  {
    path: '/doc6',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc6" */ '../views/articles/doc6.vue'),
  },
  {
    path: '/doc7',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc7" */ '../views/articles/doc7.vue'),
  },
  {
    path: '/doc8',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc8" */ '../views/articles/doc8.vue'),
  },
  {
    path: '/doc9',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc9" */ '../views/articles/doc9.vue'),
  },
  {
    path: '/doc10',
    name: 'doc',
    component: () =>
      import(/* webpackChunkName: "doc10" */ '../views/articles/doc10.vue'),
  },
]

const router = new VueRouter({
  routes,
})

export default router
