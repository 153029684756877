import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import { setSession, getSession } from './utils'
Vue.use(iView)

// import VueAwesomeSwiper from 'vue-awesome-swiper'

// import 'swiper/swiper-bundle.css'

// Vue.use(VueAwesomeSwiper)

Vue.prototype.$setSession = setSession
Vue.prototype.$getSession = getSession

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
