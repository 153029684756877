<template>
  <div class="home">
    <swiper :options="swiperOption" ref="mySwiper" class="m-swiper">
      <swiper-slide v-for="item in bannerList" :key="'banner' + item">
        <img :src="item" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div> -->
    </swiper>
    <div class="home-container" style="margin-bottom:40px;">
      <h3 class="tit">直播推荐</h3>
      <div class="live-content">
        <div class="live-div" v-for="(item, index) in liveList" :key="index + 'live'" @mouseover="showPlay = item"
          @mouseout="showPlay = ''" @click="goLive(item)">
          <div class="img">
            <img :src="item.cover" />
            <div class="over-div" v-show="showPlay == item">
              <img src="../assets/img/play.png" />
            </div>
          </div>
          <div class="live-text">
            <div class="name">{{ item.title }}</div>
            <div class="num">
              <img src="../assets/img/people.png" />
              {{ item.num }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-container">
      <h3 class="tit">娱乐焦点</h3>
      <p class="list">
        <a @click="goDetail(1)">· 汪峰《春天里》：拜访逝去的春天</a>
        <span class="time">2024/03/20</span>
      </p>
      <p class="list">
        <a @click="goDetail(2)">· 基音乐队：没有吉他钢琴也很摇滚</a>
        <span class="time">2023/09/18</span>
      </p>
      <p class="list">
        <a @click="goDetail(3)">· FOG新古典音乐 霞光浓雾总相宜(图)</a>
        <span class="time">2023/06/18</span>
      </p>
      <p class="list">
        <a @click="goDetail(4)">· 左小祖咒《最爱》：老天爷有的是亲戚</a>
        <span class="time">2023/06/15</span>
      </p>
      <p class="list">
        <a @click="goDetail(5)">· 清白之年的朴树</a>
        <span class="time">2023/06/13</span>
      </p>
      <p class="list">
        <a @click="goDetail(6)">
          · 宋冬野：我们都渴望着一鸣惊人，一朝封神，然后呢?
        </a>
        <span class="time">2023/06/13</span>
      </p>
      <p class="list">
        <a @click="goDetail(7)">· 我知道风里有诗句，不知道你</a>
        <span class="time">2023/06/09</span>
      </p>
      <p class="list">
        <a @click="goDetail(8)">· 与腰乐队最初的见面与最后的告别</a>
        <span class="time">2023/06/09</span>
      </p>
      <p class="list">
        <a @click="goDetail(9)">· 布皮树：我可以写出新东西，接近我内心的新东西</a>
        <span class="time">2023/06/03</span>
      </p>
      <p class="list">
        <a @click="goDetail(10)">· 我的《迷藏》</a>
        <span class="time">2023/06/03</span>
      </p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
// .home {
//   padding-top: 30px;
// }
.home-container {
  text-align: left;
  width: 1000px;
  margin: 40px auto 80px;
}

.m-swiper {
  width: 100%;

  // height: 600px;
  img {
    width: 100%;
  }
}

// .swiper-pagination {
//   bottom: -100px;
// }
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  text-align: center;
  color: #000;
  opacity: 1;
  background: rgba(255, 255, 255, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #ffffff !important;
}

.live-content {
  display: flex;
  width: 1000px;
  //   margin-bottom: 40px;
  justify-content: space-around;
  margin: auto;

  .live-div {
    width: 188px;
    height: 146px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    margin-bottom: 15px;

    .img {
      width: 188px;
      height: 106px;
      border-radius: 3px 3px 0px 0px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .live-text {
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 12px;
      box-sizing: border-box;

      .name {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .num {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .over-div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 106px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 3px 3px 0px 0px;

      img {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
const banner3 = require('../assets/img/直播banner03.png')
const banner4 = require('../assets/img/直播banner04.png')
const banner5 = require('../assets/img/直播banner05.png')

export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      bannerList: [
        banner3,
        banner4,
        banner5,
        'http://static.yunxi.tv/yunxi/web/music/banner/4.jpg',
        'http://static.yunxi.tv/yunxi/web/music/banner/5.jpg',
      ],
      liveList: [
        {
          id: 34,
          type: '美术',
          title: '姜浩张超画室',
          describe: '色彩静物|如何画好氛围感（第二课）',
          avatar: require('../assets/img/avatar/7.png'),
          cover: require('../assets/img/M2_MS/M2_MS_34.png'),
          url: 'http://cdn-host.media.yunxi.tv/recordM3u8/test_a87ad1efb94b4498b4ce71b7252435a8/216aa9adb6bf4b5eb5fcb97df2639471.m3u8?aliyun_uuid=51294d7862fe46738c798badb2c76639',
          show: false,
        },
        {
          id: 4,
          type: '其他',
          title: '旅游的点滴',
          describe: '走入下一段风景，告别一段往事',
          avatar: require('../assets/img/avatar/11.png'),
          cover: require('../assets/img/M6_QT/M6_QT_04.png'),
          url: '',
          show: false,
        },
        {
          id: 16,
          type: '美术',
          title: '龙行同心画室',
          describe: '',
          avatar: require('../assets/img/avatar/16.png'),
          cover: require('../assets/img/M2_MS/M2_MS_16.png'),
          url: '//cdn-host.media.yunxi.tv/recordM3u8/test_5aee8b6ee63445dab1426eb13010c310/b387dc83a0344a0e87619828c3222d48.m3u8?aliyun_uuid=e206cab2e3d44601aa8da33558a45b7f',
        },
        {
          id: 11,
          type: '美术',
          title: '广州艺巢画室',
          describe: '6月13号速写课',
          avatar: require('../assets/img/avatar/11.png'),
          cover: require('../assets/img/M2_MS/M2_MS_11.png'),
          url: '//cdn-host.media.yunxi.tv/recordM3u8/test_8f5275e6d8f74ee18c839f21e3d1ab17/cbe72c236d7a45259e482c804c01a9f9.m3u8?aliyun_uuid=b5cb9e998a3043d39f92583927a0e1f1',
        },
        {
          id: 29,
          type: '美术',
          title: '青山影视',
          describe: '济南华润置地会油画大咖线上教学',
          avatar: require('../assets/img/avatar/3.png'),
          cover: require('../assets/img/M2_MS/M2_MS_29.png'),
          url: '',
          show: false,
        },

      ],
      showPlay: '',
    }
  },
  created() {
    this.liveList.forEach(item => {
      item.num = parseInt(Math.random() * 5000)
    })
  },
  methods: {
    goDetail(index) {
      this.$router.push(`/doc${index}`)
    },
    goLive(item) {
      sessionStorage.setItem('detail', JSON.stringify(item))
      const routeData = this.$router.resolve({ path: '/detail' })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>
