import { setSession, getSession } from '../../utils'

const money = {
    state: {
       xb: getSession('XB') ? Number(getSession('XB')) : 0,
    },

    mutations: {
        SET_XB: (state, coin) => {
            state.xb = coin
        },
    },

    actions: {
        getXb({ commit }, response) {
            setSession('XB', response)
            commit('SET_XB', response)
        },
    },
}

export default money
