import { setSession, getSession } from '../../utils'
const user = {
    state: {
        userinfo: getSession('userinfo') ? getSession('userinfo') : {},
    },

    mutations: {
        SET_USER: (state, userinfo) => {
            state.userinfo = userinfo
        },
    },

    actions: {
        getUser({ commit }, response) {
            setSession('userinfo', response)
            commit('SET_USER', response)
        },
    },
}

export default user
