export function setSession(name, value) {
    if (window.opener && Object.getOwnPropertyNames(window.opener).length > 0) {
        window.opener.sessionStorage.setItem(name, value)
        sessionStorage.setItem(name, value)
    } else {
        sessionStorage.setItem(name, value)
    }
}

export function getSession(name) {
    if (window.opener && Object.getOwnPropertyNames(window.opener).length > 0) {
        return window.opener.sessionStorage.getItem(name)
    } else {
        return sessionStorage.getItem(name)
    }
}
