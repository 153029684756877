const nav = {
    state: {
       navindex: sessionStorage.getItem('SET_INDEX') ? Number(sessionStorage.getItem('SET_INDEX')) : 1,
    },

    mutations: {
        SET_INDEX: (state, index) => {
            state.navindex = index
        },
    },

    actions: {
        getIndex({ commit }, response) {
            sessionStorage.setItem('SET_INDEX', response)
            commit('SET_INDEX', response)
        },
    },
}

export default nav
