<template>
  <div class="report">
    <div
      @click="goReport"
      class="report-div"
      @mouseover="show = false"
      @mouseout="show = true"
    >
      <img src="../assets/img/report.png" v-show="show" />
      <img src="../assets/img/report_s.png" v-show="!show" />
      <div class="jubao">举报</div>
    </div>
    <!-- <div class="report-div" v-else  @hover="show = true">>
      <div class="jubao">举报</div>
    </div> -->
  </div>
</template>
<style lang="scss">
.report {
  position: fixed;
  right: 20px;
  top: 60%;
  z-index: 999;
}
.report-div {
  width: 56px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  text-align: center;
  img {
    width: 24px;
    height: 24px;
    margin-top: 5px;
  }
  .jubao {
    width: 24px;
    height: 18px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 18px;
    margin: auto;
  }
}
.report-div:hover {
  background: #20a0ff;
  cursor: pointer;
  .jubao {
    color: #fff;
  }
}
</style>
<script>
export default {
  data() {
    return {
      show: true,
    }
  },
  methods: {
    goReport() {
      window.open('https://jbts.mct.gov.cn/', '_blank')
    },
  },
}
</script>
