<template>
  <div id="app">
    <header id="nav">
      <div class="nav-content">
        <div class="logo fl">
          <img style="margin-top: 7px; height: 40px" src="https://vlebo.cn/skin/default/picture/logo2.png" />
        </div>
        <div class="fl">
          <a class="link" :class="{ 'router-active': navindex === 1 }" @click="toNext('/index', 1)">
            主页
          </a>
          <a class="link" :class="{ 'router-active': navindex === 2 }" @click="toNext('/live', 2)">
            直播间
          </a>
          <a class="link" :class="{ 'router-active': navindex === 3 }" @click="toNext('/find', 3)">
            发现音乐
          </a>
          <a class="link" :class="{ 'router-active': navindex === 4 }" @click="toNext('/recommend', 4)">
            音乐推荐
          </a>
          <!-- <a class="link"
            :class="{ 'router-active': navindex === 7 }"
            @click="toNext('/shop', 7)">
            商城
          </a> -->
          <a class="link" :class="{ 'router-active': navindex === 5 }" @click="toNext('/vip', 5)">
            会员专区
          </a>
          <a class="link" :class="{ 'router-active': navindex === 6 }" @click="toNext('/about', 6)">
            联系我们
          </a>
        </div>
        <div class="login fr" v-if="user.isLogin">
          <img class="login-user" src="./assets/img/login-user.png" alt="" />
        </div>
        <div class="login fr" v-else>
          <a id="login" class="link" @click="toLogin">登录</a>
          |
          <a class="link" @click="toRegister" style="margin-left: 15px">注册</a>
        </div>
      </div>
    </header>
    <div style="margin-top: 60px">
      <router-view @showLogin="toLogin" />
    </div>
    <Footer />
    <Report />
    <Modal v-model="islogin" title="" ok-text="是" cancel-text="否" class-name="login-modal" width="384">
      <div slot="title"></div>
      <div class="content">
        <div class="title-box">登录</div>
        <div class="input-box">
          <Input v-model.trim="user.name" style="width: 304px; margin-bottom: 24px" autocomplete="off"
            placeholder="请输入用户名"></Input>
          <Input v-model.trim="user.password" type="password" style="width: 304px" autocomplete="off"
            placeholder="请输入密码"></Input>
        </div>
        <div class="btn-box">
          <div class="btn" @click="login">登录</div>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
    <Modal v-model="isregister" title="" ok-text="是" cancel-text="否" class-name="login-modal register-modal"
      width="384">
      <div slot="title"></div>
      <div class="content">
        <div class="title-box">注册</div>
        <div class="input-box">
          <div class="require-box">
            <Input v-model.trim="registerUser.name" style="width: 304px; margin-bottom: 24px" placeholder="请输入用户名"
              required></Input>
            <div class="require-icon">*</div>
          </div>
          <div class="require-box">
            <Input class="value" v-model.trim="registerUser.phoneNum" style="width: 304px; margin-bottom: 24px"
              placeholder="请输入手机号"></Input>
            <div class="require-icon">*</div>
          </div>
          <div class="require-box">
            <Input v-model.trim="registerUser.password" style="width: 304px; margin-bottom: 24px"
              placeholder="请设置密码"></Input>
            <div class="require-icon">*</div>
          </div>
          <div class="require-box">
            <Input v-model.trim="registerUser.passwordA" style="width: 304px; margin-bottom: 24px"
              placeholder="请确认密码"></Input>
            <div class="require-icon">*</div>
          </div>
          <div class="require-box">
            <Input v-model.trim="registerUser.cardNum" style="width: 304px" placeholder="请输入身份证号"></Input>
            <div class="require-icon">*</div>
          </div>
          <div class="upload-box require-box">
            <Upload :action="'#'" :accept="'image/*'" :before-upload="getFileZm">
              <div class="no-pic" v-if="noZm">
                <div class="up">+</div>
                <div class="text">上传身份证正面</div>
              </div>
              <div class="has-pic" v-if="!noZm">
                <img :src="zmImg" alt="" />
              </div>
            </Upload>
            <div class="require-icon">*</div>
          </div>
          <div class="upload-box require-box">
            <Upload :action="'#'" :accept="'image/*'" :before-upload="getFileFm">
              <div class="no-pic" v-if="noFm">
                <div class="up">+</div>
                <div class="text">上传身份证反面</div>
              </div>
              <div class="has-pic" v-if="!noFm">
                <img :src="fmImg" alt="" />
              </div>
            </Upload>
            <div class="require-icon">*</div>
          </div>
        </div>
        <div class="btn-box">
          <Button type="primary" @click="register" :disabled="!agree">
            注册
          </Button>
        </div>
        <div class="promise-box">
          <Checkbox v-model="agree">
            我已阅读并同意
            <span>《用户注册协议》</span>
            及
            <span>《隐私政策》</span>
          </Checkbox>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<style lang="scss">
html,
body {
  background-color: #eef1f6;
  min-height: 100%;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #eef1f6;
}

.clearfix:after {
  content: '\200B';
  display: block;
  height: 0;
  clear: both;
}

a:-webkit-any-link {
  text-decoration: none;
}

#nav {
  // padding: 30px;
  width: 100vw;
  height: 60px;
  line-height: 60px;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .nav-content {
    width: 1000px;
    margin: auto;

    .logo {
      float: left;
      width: 250px;
      height: 60px;
      text-align: left;
    }
  }

  a {
    font-weight: bold;
    color: #2c3e50;
    display: inline-block;
    height: 60px;

    &.router-active {
      color: #20a0ff;
      border-bottom: 2px solid #20a0ff;
    }
  }
}

.m-container {
  width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.link {
  margin-right: 20px;
}

.link:hover {
  color: #20a0ff !important;
}

.m-about .wechat-qr {
  width: 120px;
  height: 120px;
  vertical-align: bottom;
}

.m-about .wechat-qr {
  width: 120px;
  height: 120px;
  vertical-align: bottom;
}

.n-about-tit {
  color: #fff;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  padding-left: 20px;
  margin-top: 72px;
  margin-bottom: 25px;
  background-color: #22a4e5;
}

.n-content {
  color: #333;
  font-size: 18px;
  line-height: 32px;
}

.n-content .blue {
  color: #22a4e5;
}

.n-content .ans {
  color: #888;
}

.n-qa {
  margin-bottom: 40px;
}

.n-mktmap {
  position: relative;
}

.n-mktmap .map {
  width: 100%;
}

.n-mktmap .location {
  display: none;
  font-weight: 400;
  position: absolute;
  top: -39px;
  left: -28px;
  border-radius: 2px;
  background: #22a4e5;
  color: #fff;
  font-size: 12px;
  width: 51px;
  z-index: 1;
  padding: 6px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.42);
}

.n-mktmap .location.show {
  display: inline-block;
}

.n-mktmap .location:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 4px;
  margin-left: -4px;
  margin-top: -1px;
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  border-color: transparent transparent #22a4e5 #22a4e5;
  transform-origin: 0 0;
  transform: rotate(-45deg);
}

.n-mktmap .dot {
  position: absolute;
  top: 286px;
  left: 723px;
  display: inline-block;
  width: 0;
  height: 0;
}

.n-mktmap .circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #22a4e5;
  border-radius: 100%;
  overflow: visible;
  z-index: 1;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
}

div {
  box-sizing: border-box;
}

.link {
  color: #333;
}

.link:visited {
  color: #333;
}

.container {
  width: 1000px;
  margin: 0 auto;
  text-align: left;
}

header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  color: #333;
  font-size: 18px;
}

header .active {
  color: #20a0ff;
  position: relative;
}

header .active::after {
  position: absolute;
  color: #20a0ff;
  content: ' ';
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #20a0ff;
}

a {
  text-decoration: none;
}

a:hover {
  color: #20a0ff !important;
}

a.link:hover {
  color: #20a0ff !important;
}

.logo {
  float: left;
  width: 250px;
  height: 60px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.link {
  cursor: pointer;
}

.ellipsis-2 {
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.clearfix:after {
  content: '\200B';
  display: block;
  height: 0;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

/*IE/7/6*/
.banner {
  width: 100%;
  margin: 40px 0;
}

.tit {
  color: #333;
  padding-left: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: normal;
  position: relative;
  border-left: 3px solid #20a0ff;
  margin-bottom: 15px;
}

.list {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  color: #666;
}

.list a {
  color: #666;
}

.list a:hover {
  color: #20a0ff;
}

.time {
  float: right;
}

.slick-dotted.slick-slider {
  margin-bottom: 55px;
}

.slick-slide img {
  width: 100%;
}

.slick-prev {
  z-index: 8;
  left: 25px;
}

.slick-next {
  right: 25px;
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('./assets/font/YouSheBiaoTiHei.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
</style>
<style lang="scss">
.login-user {
  width: 32px;
  height: 32px;
  margin-top: 14px;
}

.login-modal {

  .ivu-modal-header,
  .ivu-modal-footer,
  .ivu-modal-close {
    display: none;
  }

  .content {
    .title-box {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }

    .input-box {
      width: 304px;
      margin: 24px auto;
    }

    .btn-box {
      width: 304px;
      text-align: center;
      margin: 0 auto;
      height: 44px;
      line-height: 44px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;

      .btn {
        border-radius: 2px;
        background: #20a0ff;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.register-modal {
  .upload-box {
    width: 304px;
    height: 112px;
    border-radius: 2px;
    text-align: center;
    margin-top: 24px;
    cursor: pointer;
    box-sizing: border-box;

    .ivu-upload {
      width: 100%;
      height: 100%;
      border: 1px dashed #e2e2e2;
    }

    .up {
      font-size: 40px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }

    .has-pic {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .input-box {
    .require-box {
      display: flex;

      .require-icon {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff2020;
        margin-left: 4px;
      }
    }
  }

  .btn-box {
    button {
      width: 100%;
      height: 100%;
    }
  }

  .promise-box {
    width: 304px;
    margin: 12px auto 0;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;

    span {
      color: #20a0ff;
    }
  }
}

.artCon h1 {
  margin-bottom: 20px;
  font-size: 23px;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.article {
  color: #666;
  font-family: 'Microsoft Yahei', 'PingFang SC', 'Lantinghei SC',
    'Hiragino Sans GB', 'Microsoft Sans Serif', 'WenQuanYi Micro Hei', sans;
  font-size: 18px;
  line-height: 36px;
  text-align: justify;
  white-space: normal;
}

.doc {
  padding: 20px 0 40px;
}
</style>
<script>
import Footer from './components/Footer'
import Report from './components/Report'

export default {
  components: { Footer, Report },
  data() {
    return {
      islogin: false,
      isregister: false,
      user: {
        name: '',
        password: '',
        isLogin: false,
      },
      registerUser: {
        name: '',
        phoneNum: '',
        password: '',
        passwordA: '',
        cardNum: '',
        isregister: '',
      },
      noZm: true, // 没有正面
      noFm: true, // 没有反面
      zmImg: '',
      fmImg: '',
      agree: false,
    }
  },
  computed: {
    navindex() {
      return this.$store.state.nav.navindex
    },
  },
  created() {
    if (this.$getSession('userinfo') &&
      JSON.parse(this.$getSession('userinfo')).name &&
      JSON.parse(this.$getSession('userinfo')).password
    ) {
      this.user.isLogin = true
    } else {
      this.user.isLogin = false
    }
  },
  methods: {
    toLogin() {
      this.islogin = true
      this.user.name = ''
      this.user.password = ''
    },
    login() {
      if (!this.user.name) {
        this.$Message.warning('请输入用户名')
        return false
      }
      if (!this.user.password) {
        this.$Message.warning('请输入密码')
        return false
      }
      this.islogin = false
      this.user.isLogin = true
      this.$setSession('userinfo', JSON.stringify(this.user))
      setTimeout(() => {
        console.log(this.$getSession('userinfo'))
      }, 1000)
      this.$store.dispatch('getUser', JSON.stringify(this.user))
      this.$store.dispatch('getXb', 10000)
    },
    blobToDataURL(blob, cb) {
      const reader = new FileReader()
      reader.onload = function (evt) {
        const base64 = evt.target.result
        cb(base64)
      }
      reader.readAsDataURL(blob)
    },
    getFileZm(file) {
      const img = file
      if (img) {
        const that = this
        this.blobToDataURL(img, function (base64Url) {
          that.zmImg = base64Url
          that.noZm = false
        })
      }
      return false
    },
    getFileFm(file) {
      const img = file
      if (img) {
        const that = this
        this.blobToDataURL(img, function (base64Url) {
          that.fmImg = base64Url
          that.noFm = false
        })
      }
      return false
    },
    toRegister() {
      this.isregister = true
      this.registerUser.name = ''
      this.registerUser.phoneNum = ''
      this.registerUser.password = ''
      this.registerUser.passwordA = ''
      this.registerUser.cardNum = ''
    },
    register() {
      if (!this.registerUser.name) {
        this.$Message.warning('请输入用户名')
        return false
      }
      if (!this.registerUser.phoneNum) {
        this.$Message.warning('请输入手机号')
        return false
      }
      const phoneReg = /^[1][3-8][0-9]{9}$/
      if (!phoneReg.test(this.registerUser.phoneNum)) {
        this.$Message.warning('请输入正确的手机号')
        return false
      }

      if (!this.registerUser.password) {
        this.$Message.warning('请设置密码')
        return false
      }
      if (!this.registerUser.passwordA) {
        this.$Message.warning('请确认密码')
        return false
      }
      if (this.registerUser.password !== this.registerUser.passwordA) {
        this.$Message.warning('两次输入的密码不一致')
        return false
      }
      if (!this.registerUser.cardNum) {
        this.$Message.warning('请输入身份证号')
        return false
      }
      const idreg =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      if (!idreg.test(this.registerUser.cardNum)) {
        this.$Message.warning('请输入正确的身份证号')
        return false
      }
      if (!this.zmImg) {
        this.$Message.warning('请上传身份证正面')
        return false
      }
      if (!this.fmImg) {
        this.$Message.warning('请上传身份证反面')
        return false
      }
      this.isregister = false
      this.registerUser.isregister = true
      this.$Message.success('注册成功，15天内给您反馈结果')
    },
    toNext(path, index) {
      if (path === '/live' && this.$route.name === 'detail') {
        return false
      }
      this.$store.dispatch('getIndex', index)
      this.$router.push({ path: path })
    },
  },
}
</script>
